<template>
    <div class="p-fluid mb-3">       
        <div class="grid">
            <div class="lg:col-12 md:col-12 sm:col-12 align-items-center justify-content-center">
                <h5>Guide</h5>
                 <ol>
                    <li>
                        <p class="line-height-3 m-0"><strong>Please download the template first</strong></p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0"><strong>Ensure that the uploaded file is in Excel or CSV format.</strong></p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0"><strong>Make sure the file size does not exceed 2MB.</strong></p>
                    </li>
                    <li><p class="line-height-3 m-0"><strong>Ensure the file format matches the provided template.</strong></p></li>
                    <li><p class="line-height-3 m-0"><strong>Do not modify the following columns as these are system-generated:</strong></p> 
                        <ul>
                            <li>Industry</li>
                            <li>Channel</li>
                            <li>Outlet Type Code - System</li>
                            <li>Outlet Type Name - System</li>
                        </ul>
                    </li>
                    <li><p class="line-height-3 m-0"><strong>You may only modify or add data in the following columns, marked with <span class="important">*</span> for required fields:</strong></p>
                        <ul>
                            <li>Outlet Type Code - User Definition <span class="important">*</span>: The outlet type code defined by you.</li>
                            <li>Outlet Type Name - User Definition <span class="important">*</span>: The outlet type name defined by you.</li>
                            <li>Time Estimate (Minutes at Store) <span class="important">*</span>: The estimated time spent at the store (in minutes).</li>
                            <li>Frequency of Call (Number of Visit in a Month) <span class="important">*</span>: The number of visits per month.</li>
                            <li>Estimation Success EC per Call (percent) <span class="important">*</span>: The estimated success rate per visit (in percentage).</li>
                            <li>Estimation Unload Times (minutes) <span class="important">*</span>: The estimated unloading time (in minutes).</li>
                            <li>Remark: Additional notes or comments.</li>
                        </ul>
                    </li>
                </ol>
                <ol>
                </ol>
                <div class="line-height-3 m-0" style="color:red;">Note: Please follow the step-by-step instructions carefully to ensure a successful data upload.</div>
                <h5>Download Template</h5>
                <div class="p-fluid">
                    <div class="formgrid grid">
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div class="field ml-2">
                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplate('xlsx')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sm:col-12 md:col-12 lg:col-6">
                <BlockUI :blocked="blockPanel">
                    <form>
                        <div class="p-fluid">
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6">
                                    <!-- FORM -->
                                </div>
                            </div>
                        </div>
                        <FileUpload ref="fileUpload" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Choose File" uploadLabel="Upload" cancelLabel="Cancel" :customUpload="true" @uploader="onUpload" :fileLimit="1" :maxFileSize="2000000">
                        <template #empty>
                            <p>Drag and drop the file here to upload.</p>
                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                        </template>
                        </FileUpload>
                    </form>
                </BlockUI>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ['submit'], 
    props: ['id'],
    data() {
        return {
            // loading
            loadingDownloadTemplate: false,

            // upload
            blockPanel: false,
            file: null,
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    computed: {
        ...mapGetters(['errors']),
    },
    methods: {
        // DOWNLOADTEMPLATE
        downloadTemplate(ext){
            this.loadingDownloadTemplate= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-type/generate-template',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "project_id" : this.id,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Template Upload Outlet Type.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();

                this.loadingDownloadTemplate= false;

            })
            .catch((err) => {
                console.log(err);

                this.loadingDownloadTemplate= false;
            });
        },
        //UPLOAD
        onUpload(event) {

            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('file', this.file);
            data.append('project_id', this.id);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-type/import',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;

                this.clearForms();

                this.$emit('submit');
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
            });
        },
        clearForms() {
            this.file = null;
        },
    }
}
</script>