<template>
<div v-if="reloadForm == false">
    <div v-if="itemCreate">
        <Breadcrumb :home="home" :model="items" class="mb-3"/>
        <div class="grid">
            <div class="col-12">
                <div class="card">
                    <Toast/>
                    <Error :errors="errors"/>

                    <Message severity="info" :closable="false">
                        <div>Project Code: {{this.itemCreate?.project_code}}</div>
                        <div>Project Name: {{this.itemCreate?.project_name}}</div>
                    </Message>

                    <!-- <Fieldset legend="Add New" :toggleable="true" :collapsed="true" class="mb-3">
                        <Create :id="itemCreate.mcu_id" @submit="getDataTable()" ref="create"/>
                    </Fieldset> -->

                    <Fieldset legend="Import" :toggleable="true" :collapsed="true" class="mb-3">
                        <Import :id="itemCreate.project_id" @submit="getDataTable()" ref="import"/>
                    </Fieldset>

                    <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                        <Filter @submit="getDataTable()" ref="filter"/>
                    </Fieldset>


                    <DataTable :value="dataTable" editMode="row" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                        <template #header>
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <div>
                                    <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')"/>
                                    <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')"/>
                                </div>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                                </span>
                            </div>
                        </template>
                        <Column ref="asd"  :styles="{width:'10%', 'min-width':'8rem'}" :bodyStyle="{'text-align':'center'}">
                            <template #body="slotProps">
                                <template v-if="!isRowEditing(slotProps.data.number)">
                                <Button  :disabled="isEditing && !editingRowsData.includes(slotProps.data.number)" icon="pi pi-pencil" @click="editRow(slotProps.data.number)"></Button>
                                </template>
                                <template v-else>
                                    <Button icon="pi pi-check" class="p-button-success" @click="onRowEditSave(slotProps.data)" ></Button>
                                    <Button icon="pi pi-times" class="p-button-danger" @click="cancelEdit(slotProps.data)" ></Button>
                                </template>
                            </template>
                        </Column>
                        <Column field="number" header="#" :style="{width:'20px'}" >
                            <template #body="slotProps">
                            {{ slotProps.data.number }}
                            </template>
                        </Column>
                        <Column field="industry_name" header="Industry">
                            <template #body="slotProps">
                                {{slotProps.data.industry_name}}
                            </template>
                        </Column>
                        <Column field="channel_name" header="Channel">
                            <template #body="slotProps">
                                {{slotProps.data.channel_name}}
                            </template>
                        </Column>
                        <Column field="otype_code">
                            <template #header>
                                Outlet Type Code <br>
                                (System)
                            </template>
                            <template #body="slotProps">
                                {{slotProps.data.otype_code}}
                            </template>
                        </Column>
                        <Column field="otype_name">
                            <template #header>
                                Outlet Type Name <br>
                                (System)
                            </template>
                            <template #body="slotProps">
                                {{slotProps.data.otype_name}}
                            </template>
                        </Column>
                        <Column field="code_by_user">
                            <template #header>
                                Outlet Type Code <br>
                                (User Definition)
                            </template>
                            <template #body="slotProps">
                                <template v-if="isRowEditing(slotProps.data.number)">
                                    <InputText v-model="slotProps.data.code_by_user" v-if="isRowEditing(slotProps.data.number)" autofocus :class="{ 'p-invalid': errorEdit[slotProps.data.number]?.code_by_user }"/>
                                    <br>
                                    <small class="p-invalid" style="color: red" v-if="errorEdit[slotProps.data.number]?.code_by_user" >{{ errorEdit[slotProps.data.number]?.code_by_user[0] }}</small >
                                </template>
                                <template v-else>
                                    <span>{{ slotProps.data.code_by_user }}</span>
                                </template>
                            </template>
                        </Column>
                        <Column field="name_by_user">
                            <template #header>
                                Outlet Type Name <br>
                                (User Definition)
                            </template>
                            <template #body="slotProps">

                                <template v-if="isRowEditing(slotProps.data.number)">
                                    <InputText v-model="slotProps.data.name_by_user" v-if="isRowEditing(slotProps.data.number)" :class="{ 'p-invalid': errorEdit[slotProps.data.number]?.name_by_user }"/>
                                <br>
                                <small class="p-invalid" style="color: red" v-if=" errorEdit[slotProps.data.number]?.name_by_user" >{{  errorEdit[slotProps.data.number]?.name_by_user[0] }}</small >
                                </template>
                                <template v-else>
                                    <span>{{ slotProps.data.name_by_user }}</span>
                                </template>

                            </template>
                        </Column>
                        <Column field="time_at_store">
                            <template #header>
                                Time Estimate <br>
                                (Minutes at Store)
                            </template>
                            <template #body="slotProps">

                                <template v-if="isRowEditing(slotProps.data.number)">
                                    <InputText v-model="slotProps.data.time_at_store" v-if="isRowEditing(slotProps.data.number)" :class="{ 'p-invalid': errorEdit[slotProps.data.number]?.time_at_store }"/>
                                <br>
                                <small class="p-invalid" style="color: red" v-if="errorEdit[slotProps.data.number]?.time_at_store" >{{ errorEdit[slotProps.data.number]?.time_at_store[0] }}</small >
                                </template>
                                <template v-else>
                                    <span>{{ slotProps.data.time_at_store }}</span>
                                </template>

                               
                            </template>
                        </Column>
                        <Column field="frequency_of_call">
                            <template #header>
                                Frequency of Call <br>
                                (Number of Visit in a Month)
                            </template>
                            <template #body="slotProps">

                                <template v-if="isRowEditing(slotProps.data.number)">
                                    <Dropdown style="width: 100%;" v-model="slotProps.data.frequency_of_call" v-if="isRowEditing(slotProps.data.number)" :options="dataDropdownFrequency" optionLabel="name" optionValue="code" placeholder="Select Frequency" :class="{ 'p-invalid': errorEdit[slotProps.data.number]?.frequency_of_call }"/>
                                <br>
                                <small class="p-invalid" style="color: red" v-if="errorEdit[slotProps.data.number]?.frequency_of_call" >{{ errorEdit[slotProps.data.number]?.frequency_of_call[0] }}</small >
                                </template>
                                <template v-else>
                                    <span>{{ slotProps.data.frequency_of_call }}</span>
                                </template>

                               
                            </template>
                        </Column>
                        <Column field="est_ec">
                            <template #header>
                                Estimation Success EC per Call <br>
                                (percent)
                            </template>
                            <template #body="slotProps">

                                <template v-if="isRowEditing(slotProps.data.number)">
                                    <InputNumber  v-model="slotProps.data.est_ec" v-if="isRowEditing(slotProps.data.number)" mode="decimal" :useGrouping="false" :class="{ 'p-invalid': errorEdit[slotProps.data.number]?.est_ec }"/>
                                <br>
                                <small class="p-invalid" style="color: red" v-if="errorEdit[slotProps.data.number]?.est_ec" >{{ errorEdit[slotProps.data.number]?.est_ec[0] }}</small >
                                </template>
                                <template v-else>
                                    <span>{{ slotProps.data.est_ec }}</span>
                                </template>

                               
                            </template>
                        </Column>
                        <Column field="est_unload">
                            <template #header>
                                Estimation Unload Times <br>
                                (minutes)
                            </template>
                            
                            <template #body="slotProps">

                                <template v-if="isRowEditing(slotProps.data.number)">
                                    <InputNumber v-model="slotProps.data.est_unload" v-if="isRowEditing(slotProps.data.number)" mode="decimal" :useGrouping="false" :class="{ 'p-invalid': errorEdit[slotProps.data.number]?.est_unload }"/>
                                <br>
                                <small class="p-invalid" style="color: red" v-if="errorEdit[slotProps.data.number]?.est_unload" >{{ errorEdit[slotProps.data.number]?.est_unload[0] }}</small >
                                </template>
                                <template v-else>
                                    <span>{{ slotProps.data.est_unload }}</span>
                                </template>
                               
                            </template>
                        </Column>
                        <Column field="description" header="Remark">
                            <template #body="slotProps">
                                <template v-if="isRowEditing(slotProps.data.number)">
                                    <InputText v-model="slotProps.data.description" v-if="isRowEditing(slotProps.data.number)" :class="{ 'p-invalid': errorEdit[slotProps.data.number]?.description }"/>
                                <br>
                                <small class="p-invalid" style="color: red" v-if="errorEdit[slotProps.data.number]?.description" >{{ errorEdit[slotProps.data.number]?.description[0] }}</small >
                                </template>
                                <template v-else>
                                    <span>{{ slotProps.data.description }}</span>
                                </template>
                               
                               
                            </template>
                        </Column>
                        <template #empty>
                            No data found.
                        </template>
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <template #footer>
                            In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                        </template>
                    </DataTable>

                    <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                        <template>
                            {{totalItemsCount}}
                        </template>
                    </Paginator>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h3 class="text-center">Project Not Found</h3>
    </div>
</div>
<div v-else>
    <SkeletonComponent />
</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../../../utils/helpers';
// import Create from './Create.vue';
import Filter from './Filter.vue';
import Import from './Import.vue';
import Error from '../../../Error.vue';
import SkeletonComponent from '../../../SkeletonComponent.vue';

export default {
    components: {
		// 'Create': Create,
		'Filter': Filter,
		'Import': Import,
		'Error': Error,
		'SkeletonComponent': SkeletonComponent,
	},
    data() {
        return {
            // breadcrumb
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Project', to: '/project'},
                {label: 'Outlet Type'},
            ],

            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            reloadForm: false,

            // dataDropdown
            dataDropdownFrequency: [
                {name: '1', code: 1},
                {name: '2', code: 2},
                {name: '4', code: 4},
                {name: '8', code: 8},
                {name: '12', code: 12},
                {name: '16', code: 16},
                {name: '20', code: 20},
            ],

            project_id: null,

            itemCreate: {},

            // edit,delete
            item: {},
            editingRows: [],
            editingRowsData: [],
            isEditing: null,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    created() {
        this.project_id = this.$route.params.id;
        this.getItemById(this.project_id);
    },
    mounted() {
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors','errorEdit']),
    },
    methods: {
        isRowEditing(number) {
            return this.editingRowsData.includes(number);
        },
        editRow(number) {
            this.editingRowsData = [];
            if (!this.editingRowsData.includes(number)) {
                this.editingRowsData.push(number);
            }
            this.isEditing = true;
        },
        cancelEdit(data) {
            this.editingRowsData = this.editingRowsData.filter(rowNumber => rowNumber !== data.number);
            this.editingRows = [];
            this.isEditing = false;
            this.getDataTable();
        },
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // EDIT 
        onRowEditSave(event) {
            // let { newData } = event;

            let newData = event;

            // let number = newData.number;

            this.loading = true;

            console.log('newdata', event);

            this.editingRows = [...this.editingRows, newData];

            this.editingRowsData = this.editingRowsData.filter(rowNumber => rowNumber !== newData.number);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-type/create',
                data: {
                    "project_id": newData.project_id,
                    "outlet_type_id": newData.otype_id,
                    "code_by_user": newData.code_by_user,
                    "name_by_user": newData.name_by_user,
                    "time_at_store": newData.time_at_store,
                    "frequency_of_call": newData.frequency_of_call,
                    "est_ec": newData.est_ec,
                    "est_unload": newData.est_unload,
                    "description": newData.description
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {

                    console.log('res', res)

                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.isEditing = false;
                    // this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();

                     // Hapus error pada baris yang berhasil disimpan
                    let newErrorEdit = { ...this.$store.state.errorEdit };
                    delete newErrorEdit[newData.number];
                    this.$store.commit('setErrorEdit', newErrorEdit);

                    console.log(this.editingRows);
                }
            })
            .catch((err) => {
                console.log('err', err);
                this.loading = false;

                // this.editingRows = [...this.editingRows, newData];

                //menampilkan error dibaris datatable ketika edit
                let newErrorEdit = { ...this.$store.state.errorEdit };
                newErrorEdit[newData.number] = err.response.data.data;
                this.$store.commit('setErrorEdit', newErrorEdit);

                if (!this.editingRowsData.includes(newData.number)) {
                    this.editingRowsData.push(newData.number);
                }

                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });

        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-type',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "project_id" : this.project_id,
                    "industry_id" : this.$refs.filter.filters.industry_id,
                    "channel_id" : this.$refs.filter.filters.channel_id,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-type/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "project_id" : this.project_id,
                "industry_id" : this.$refs.filter.filters.industry_id,
                "channel_id" : this.$refs.filter.filters.channel_id,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Outlet Type Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
        getItemById(id) {
            this.reloadForm = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/project/check',
                params: {
                    "project_id": id,
                }
            })
            .then(res => {
                this.reloadForm = false;

                if (res) {

                    this.$store.commit('setErrors', {});
                    this.itemCreate = res.data.data;

                    setTimeout(() => {
                        this.getDataTable();
                    }, 200);

                }
            }, (this))
            .catch((err) => {
                this.itemCreate = null;
                console.log(err);
                this.reloadForm = false;
            });
        }
    }
}
</script>