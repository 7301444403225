<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Industry</label>
                <Dropdown id="industry_id" dataKey="industry_id" v-model="filters.industry_id" :loading="loadingDropdownIndustry"
                    :options="dataDropdownIndustry"
                    optionLabel="industry_name" optionValue="industry_id" placeholder="Select Industry"
                    :filter="true" :showClear="true" @filter="searchDropdownIndustry($event, 'filter')" @change="changeChannel()"/>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Channel</label>
                <Dropdown id="channel_id" dataKey="channel_id" v-model="filters.channel_id" :loading="loadingDropdownChannel"
                    :options="dataDropdownChannel"
                    optionLabel="channel_name" optionValue="channel_id" placeholder="Select Channel"
                    :filter="true" :showClear="true" @filter="searchDropdownChannel($event, 'filter')"/>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
</template>

<script>

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownIndustry: false,
            loadingDropdownChannel: false,

            // dataDropdown
            dataDropdownIndustry: null,
            dataDropdownChannel: null,

            // filter
            filters: {
                industry_id: null,
                channel_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownIndustry('');
    },
    created(){
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownIndustry(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dindustry.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownIndustry = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-industry',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownIndustry = res.data.data;
                        this.loadingDropdownIndustry = false;
                    }else if(purpose == null){
                        this.dataDropdownIndustry = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeChannel() {
            this.filters.channel_id = null;
            this.dataDropdownChannel = null;
            this.searchDropdownChannel('');
        },
        searchDropdownChannel(event, purpose, valueEdit) {
            setTimeout(() => {

                if(this.filters.industry_id){
                    if (valueEdit) {
                        this.$refs.dchannel.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownChannel = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-industry-channel',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "industry_id": this.filters.industry_id
                        }
                    })
                        .then(res => {

                            if (purpose == "filter") {
                                this.dataDropdownChannel = res.data.data;
                                this.loadingDropdownChannel = false;
                            } else if (purpose == null) {
                                this.dataDropdownChannel = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

